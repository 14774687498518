// import '../scss/theme.scss';

document.addEventListener('DOMContentLoaded', () => {

	// Get all "navbar-burger" elements
	const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

	// Check if there are any navbar burgers
	if ($navbarBurgers.length > 0) {

		// Add a click event on each of them
		$navbarBurgers.forEach( el => {
			el.addEventListener('click', () => {

				// Get the target from the "data-target" attribute
				const target = el.dataset.target;
				const $target = document.getElementById(target);

				// Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
				el.classList.toggle('is-active');
				$target.classList.toggle('is-active');
				if (document.querySelector('header.fade-in') == null) {
					document.querySelector('header.site-header').classList.toggle('is-active');
				}
			});
		});
	}

});

//add class on scroll
window.onscroll = function() {
	var currentScrollPos = window.pageYOffset;
	if (currentScrollPos > 150) {
		document.querySelector('header.site-header').classList.add('fade-in');
	} else {
		document.querySelector('header.site-header').classList.remove('fade-in');
	}
}

//smooth scrolling
jQuery($ => {
	// The speed of the scroll in milliseconds
	const speed = 1000;
	$('a[href*="#"]')
		.filter((i, a) => a.getAttribute('href').startsWith('#') || a.href.startsWith(`${location.href}#`))
		.unbind('click.smoothScroll')
		.bind('click.smoothScroll', event => {
			const targetId = event.currentTarget.getAttribute('href').split('#')[1];
			const targetElement = document.getElementById(targetId);

			if (targetElement) {
				event.preventDefault();
				//history.pushState(null, null, null);
				var windowWidth = jQuery( window ).width();
				var scrollHelp;
				if(windowWidth < 992) {
					scrollHelp = 450;
				} else {
					scrollHelp = 125;
				}
				$('html, body').animate({ scrollTop: $(targetElement).offset().top - scrollHelp }, speed);
			}
		});
});

//loop through content to pull all h2s and add anchors to h2
if (document.getElementById("toc-list") != null) {
	var titleArray = [];
	var h2Titles = document.querySelectorAll('.entry__content h2');
	if (h2Titles.length > 0) {
		h2Titles.forEach(function (element, index) {
			element.setAttribute('id', 'toc' + index);
			titleArray.push(element.textContent);
		});
		titleArray.forEach(function (element, index) {
			var li = document.createElement("li");
			li.innerHTML = '<a href="#toc' + index + '">' + element + '</a>';
			document.getElementById("toc-list").appendChild(li);
		});
	} else {
		document.querySelector(".toc-nav").style.display = "none";
	}
	document.querySelector('.toc-nav').onclick = e => {
		var menu = document.querySelector('.toc-nav')
		menu.classList.toggle('is-active');
	}
}

document.querySelector('.search a').onclick = e => {
	document.querySelector('.site-header-search').classList.remove('hide');
}
document.querySelector('.site-search-reset').onclick = e => {
	document.querySelector('.site-header-search').classList.add('hide');
}
